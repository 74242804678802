.indicator {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  margin-right: 3px;
  margin-left: 3px;
  cursor: pointer;
  padding: 2px;
  text-align: center;
  background-color: #fcfcfc;
  border: 0.3px solid #1a1c3a;
  border-radius: 4px;
  width: 20px;
  height: 3px;
}

.indicator.active {
  background: #1a1c3a;
  color: #fff;
  width: 30px;
  height: 3px;
  border: 0.3px solid #1a1c3a;
}
.react-slideshow-container + ul.indicators {
  padding: 0;
}
.react-slideshow-container button {
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  bottom: 4%;
  height: 0px;
}
.react-slideshow-container .nav:first-of-type {
  left: 42%;
}
.react-slideshow-container .nav:last-of-type {
  right: 42%;
}
.react-slideshow-container .default-nav {
  height: 40px;
  top: 100%;
  background-color: #fcfcfc;
  border-radius: 4px;
  cursor: pointer;
}
@media screen and (max-width: 900px) {
  .react-slideshow-container .nav:first-of-type {
    left: 35%;
  }
  .react-slideshow-container .nav:last-of-type {
    right: 35%;
  }
}
@media screen and (max-width: 500px) {
  .react-slideshow-container .nav:first-of-type {
    left: 20%;
  }
  .react-slideshow-container .nav:last-of-type {
    right: 20%;
  }
}

@media screen and (max-width: 400px) {
  .react-slideshow-container .nav:first-of-type {
    left: 15%;
  }
  .react-slideshow-container .nav:last-of-type {
    right: 15%;
  }
}

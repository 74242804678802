.nav-link {
  color: #fcfcfc !important;
  text-decoration: none;
  padding: 0 20px !important;
  height: 35px;
}
.menu-link {
  color: #fcfcfc !important;
  text-decoration: none;
  padding: 15px 0;
}

.active-link {
  position: relative;
  padding: 0 20px !important;
  text-decoration: none;
  color: #e5cc82 !important;
}
.active-menu-link {
  position: relative;
  text-decoration: none;
  color: #e5cc82 !important;
  margin-bottom: 15px;
}
.active-link::after,
.active-menu-link::after {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #e5cc82;
  transform: scaleX(1);
  transition: transform 0.2s ease-in-out;
  z-index: 99999;
}

.active-link:hover::after,
.active-menu-link:hover::after {
  transform: scaleX(1);
}

.active-link:focus::after,
.active-link.active::after,
.active-menu-link.active::after {
  transform: scaleX(1);
  /* z-index: 9999999; */
  bottom: -1px;
  border-bottom: 2px solid #e5cc82;
}
.ant-select-selector {
  background-color: green;
}
/* .MuiPopover-paper {
  background: red !important;
  top: 20px !important;
} */

.MuiOutlinedInput-notchedOutline {
  border: none !important;
}
/* .MuiSelect-select {
  padding-right: 0px !important;
  padding: 16.5px 0px !important;
} */

/* .MuiSvgIcon-root {
  background-image: url('../../../assets/icons/close.svg');
  background-repeat: no-repeat;
  color: transparent !important;
} */
.MuiMenu-list {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

/* .MuiSvgIcon-root {
  background-image: url('../../../assets/icons/close.svg');
  background-repeat: no-repeat;
  color: transparent !important;
} */

.btn {
  padding: 18px 48px;
  gap: 10px;
  width: 182px;
  height: 57px;
  background: #fcfcfc;
  border: 1px solid #1a1c3a;
  border-radius: 2px 20px;
}

.covering-row {
  display: -ms-flexbox; /* IE10 */
  display: flex;
  -ms-flex-wrap: wrap; /* IE10 */
  flex-wrap: wrap;
  /* margin-bottom: 119px; */
}

/* Create four equal columns that sits next to each other */
.covering-column {
  -ms-flex: 50%; /* IE10 */
  flex: 50%;
  max-width: 50%;
}

.covering-column img {
  vertical-align: middle;
  width: 100%;
}

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 800px) {
  .covering-column {
    -ms-flex: 50%;
    flex: 50%;
    max-width: 50%;
  }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .covering-column {
    /* -ms-flex: 100%;
    flex: 100%;
    max-width: 100%; */
  }
}

label {
  cursor: pointer;
}

.item {
  /* margin-bottom: 3em; */
  margin-top: 10px;
}

/* checkbox-circle2 */
.checkbox-circle-blue input[type='radio'] {
  display: none;
}
.checkbox-circle-blue input[type='radio'] + label {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 20px;
  font: 14px/20px 'Open Sans', Arial, sans-serif;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.checkbox-circle-blue input[type='radio'] + label:before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  background: #003598;
  border-radius: 1em;
  border: 2px solid #fff;
  width: 2em;
  height: 2em;
}
.checkbox-circle-blue input[type='radio']:checked + label:before {
  border-radius: 1em;
  border: 2px solid #fff;
  width: 2em;
  height: 2em;
  background: #003598;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
/* checkbox-circle2 end */

.checkbox-circle-red input[type='radio'] {
  display: none;
}
.checkbox-circle-red input[type='radio'] + label {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 20px;
  font: 14px/20px 'Open Sans', Arial, sans-serif;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.checkbox-circle-red input[type='radio'] + label:before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  background: #b5021a;
  border-radius: 1em;
  border: 2px solid #fff;
  width: 2em;
  height: 2em;
}
.checkbox-circle-red input[type='radio']:checked + label:before {
  border-radius: 1em;
  border: 2px solid #fff;
  width: 2em;
  height: 2em;
  background: #b5021a;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.checkbox-circle-black input[type='radio'] {
  display: none;
}
.checkbox-circle-black input[type='radio'] + label {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 20px;
  font: 14px/20px 'Open Sans', Arial, sans-serif;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.checkbox-circle-black input[type='radio'] + label:before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  background: #0c0c0c;
  border-radius: 1em;
  border: 2px solid #fff;
  width: 2em;
  height: 2em;
}
.checkbox-circle-black input[type='radio']:checked + label:before {
  border-radius: 1em;
  border: 2px solid #fff;
  width: 2em;
  height: 2em;
  background: #0c0c0c;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.checkbox-circle-grey input[type='radio'] {
  display: none;
}
.checkbox-circle-grey input[type='radio'] + label {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 20px;
  font: 14px/20px 'Open Sans', Arial, sans-serif;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.checkbox-circle-grey input[type='radio'] + label:before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  background: #e1dfdf;
  border-radius: 1em;
  border: 2px solid #fff;
  width: 2em;
  height: 2em;
}
.checkbox-circle-grey input[type='radio']:checked + label:before {
  border-radius: 1em;
  border: 2px solid #fff;
  width: 2em;
  height: 2em;
  background: #e1dfdf;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

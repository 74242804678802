.elementToFadeInAndOut {
  background-repeat: no-repeat;
  animation: move 3s ease infinite;
  animation-duration: 4s;
  animation-delay: 3s;
}
.control-dots {
  /* display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  bottom: 4%;
  height: 0px; */
  display: flex;
  align-items: center;
  flex-direction: row;
  position: relative;
  width: auto;
  height: 20px;
  margin: 0px;
  column-gap: 10px;
}
.control-dots .dot {
  width: 20px !important;
  height: 3px !important;
  border-radius: 4px !important;
}
.control-dots .dot.selected {
  width: 30px !important;
  height: 3px !important;
  border-radius: 4px !important;
  background: #1a1c3a !important;
}

.carousel-status {
  display: none;
}

/* @keyframes move {
  to {
    opacity: 0;
    transform: translateY(5%);
  }
  from {
    opacity: 1;
    transform: translateX(0%);
  }
} */

@-webkit-keyframes fadeinout {
  /* 0% {
    opacity: 1;
    top: 50px;
    right: 100px;
  }
  100% {
    opacity: 0;
    bottom: 50px;
    left: 20px;
  } */
  /* to {
    opacity: 0;
    transform: translateY(100%);
  }
  from {
    opacity: 1;
  } */
  /* from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(10%);

    opacity: 0;
  } */
  /* 0% {
    opacity: 0;
    right: 0px;
    top: 0px;
  }
  50% {
    opacity: 1;
    right: 100%;
    top: 5%;
  }
  100% {
    opacity: 0;
  } */
  /* 0% {
    top: 0;
    right: 0;
    opacity: 0;
  }
  30% {
    top: 0px;
    opacity: 1;
  }
  68%,
  72% {
    top: 150px;
    right: 150px;
    opacity: 1;
  }
  100% {
    top: 100px;
    right: 100%;
    opacity: 0;
  } */
}

/* 0% {
  opacity: 0;
}
4% {
  opacity: 0;
  transform: translateY(-30px);
}

25% {
  opacity: 1;
  transform: translateY(0px);
}
30% {
  opacity: 0;
  transform: translateY(30px);
}

100% {
  opacity: 0;
} */
.carousel .slider-wrapper.axis-horizontal .slider .slide {
  display: flex;
  justify-content: space-evenly;
}

.carousel.carousel-slider .control-arrow {
  width: 40px;
  height: 40px;
  top: auto;
  background-color: white;
  border-radius: 4px;
  cursor: pointer;
}

.carousel .control-prev.control-arrow:before {
  border-left: unset;
  border-right: unset;
}
.carousel .control-next.control-arrow:before {
  border-left: unset;
  border-right: unset;
}
.carousel .control-prev.control-arrow,
.carousel .control-prev.control-arrow:hover {
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('../../assets/icons/left_lg.svg');
  /* background-size: contain; */
}
.carousel .control-next.control-arrow,
.carousel .control-next.control-arrow:hover {
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('../../assets/icons/right_lg.svg');
  /* background-size: contain; */
}
.carousel .control-next.control-arrow {
  right: 36%;
  /* bottom: 2%; */
}
.carousel .control-prev.control-arrow {
  left: 36%;
  /* bottom: 2%; */
}
.carousel .control-dots {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  /* bottom: 4%; */
  /* height: 0px; */
  /* background: #1a1c3a; */
}
@media screen and (max-width: 1300px) {
  .carousel .control-next.control-arrow {
    right: 31%;
    width: 38px;
    height: 38px;
    background-image: url('../../assets/icons/right_md.svg');
  }

  .carousel .control-prev.control-arrow {
    left: 31%;
    width: 38px;
    height: 38px;
    background-image: url('../../assets/icons/left_md.svg');
  }
}
@media screen and (max-width: 980px) {
  .carousel .control-next.control-arrow {
    right: 28%;
    width: 32px;
    height: 32px;
  }
  .carousel .control-prev.control-arrow {
    left: 28%;
    width: 32px;
    height: 32px;
  }
  .control-dots .dot {
    width: 15px !important;
    height: 3px !important;
  }
  .control-dots .dot.selected {
    width: 20px !important;
    height: 3px !important;
  }
}
@media screen and (max-width: 750px) {
  .carousel .control-next.control-arrow {
    right: 20%;
    /* width: 32px;
    height: 32px; */
    background-image: url('../../assets/icons/right_sm.svg');
  }
  .carousel .control-prev.control-arrow {
    left: 20%;
    /* width: 32px;
    height: 32px; */
    background-image: url('../../assets/icons/left_sm.svg');
  }
  .control-dots .dot {
    width: 15px !important;
    height: 3px !important;
  }
  .control-dots .dot.selected {
    width: 20px !important;
    height: 3px !important;
  }
}
@media screen and (max-width: 540px) {
  .carousel .slider-wrapper.axis-horizontal .slider .slide {
    height: 200px;
  }
  .carousel .control-next.control-arrow {
    right: 12%;
    width: 20px;
    height: 20px;
  }
  .carousel .control-prev.control-arrow {
    left: 12%;
    width: 20px;
    height: 20px;
  }
  .control-dots .dot {
    width: 10px !important;
    height: 3px !important;
  }
  .control-dots .dot.selected {
    width: 15px !important;
    height: 3px !important;
  }
  .control-dots {
    height: 0px;
  }
}

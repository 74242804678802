.month-grid-container {
  display: grid;
  width: 100%;
  /* grid-template-columns: repeat(4, 1fr); */
  /* grid-template-rows: repeat(3, 1fr); */
  grid-gap: 10px;
}

.month-grid-item {
  /* background-color: #ddd; */
  padding: 10px;
  text-align: start;
  cursor: pointer;
}
.month-grid-item:hover {
  background: rgba(28, 27, 31, 0.08);
}

.year-grid-container {
  display: grid;
  width: 100%;
  /* grid-template-columns: repeat(3, 1fr); */
  /* grid-template-rows: repeat(3, 1fr); */
  grid-gap: 10px;
}

.year-grid-item {
  cursor: pointer;
  padding: 10px;
  text-align: start;
}
.year-grid-item:hover {
  background: rgba(28, 27, 31, 0.08);
}
.datePicker-container {
  width: 100%;
  padding: 6px;
  background-color: #fff;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.navWrapper {
  display: flex;
  margin: 0 14px;
  align-items: center;
}

.navValue {
  display: flex;
  margin: 10px;
}

.arrow {
  margin: 0 10px;
}

.actions {
  display: flex;
  justify-content: end;
  margin: 20px 0 10px 0;
}

.btnCancel {
  margin-right: 20px;
  font-family: Rubik;
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
}

.btnOK {
  margin-right: 16px;
  font-family: Rubik;
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
}

.dayPicker-container {
  display: inline-grid;
  width: 100%;
}

.day {
  height: 38px;
  width: 38px;
  text-align: center;
}

.daySpan {
  /* border-radius: 50%; */
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.daySpan:active {
  background: #0c0c83;
  color: white;
}

.navContainer {
  display: flex;
  justify-content: center;
}

.selectedOption {
  width: 314px;
  height: 40px;
}

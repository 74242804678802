.carousel-control-prev,
.carousel-control-next {
  width: 40px;
  height: 40px;
  top: auto;
  background-color: #fcfcfc;
  border-radius: 4px;
  cursor: pointer;
}
.carousel-control-prev-icon {
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('../../assets/icons/left_lg.svg');
  background-size: contain;
}
.carousel-control-next-icon {
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('../../assets/icons/right_lg.svg');
  background-size: contain;
}

.carousel-control-next {
  right: 44%;
  bottom: 4%;
}
.carousel-control-prev {
  left: 44%;
  bottom: 4%;
}
.carousel-indicators {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  bottom: 4%;
  height: 0px;
  background: #1a1c3a;
}
.carousel-indicators [data-bs-target] {
  width: 20px;
  height: 3px !important;
  border-radius: 4px;
}
.carousel-indicators .active {
  width: 30px;
  height: 3px !important;
  border-radius: 4px;
}
.carousel-caption {
  left: 0;
  right: 0;
}
.pageName {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  align-items: flex-start;
  font-family: 'Rubik';
  font-weight: 700;
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  color: #fcfcfc;
  margin-left: 66px;
  margin-bottom: 51px;
}
@media screen and (max-width: 1300px) {
  .carousel-control-next {
    right: 41.5%;
  }
  .carousel-control-prev {
    left: 41.5%;
  }
  .carousel-control-prev,
  .carousel-control-next {
    width: 38px;
    height: 38px;
  }
}
@media screen and (max-width: 980px) {
  .carousel-control-next {
    right: 38.5%;
  }
  .carousel-control-prev {
    left: 38.5%;
  }
}
@media screen and (max-width: 750px) {
  .pageName {
    font-size: 32px;
    line-height: 38px;
  }

  .carousel-control-prev,
  .carousel-control-next {
    width: 32px;
    height: 32px;
  }
  .carousel-control-next {
    right: 31%;
  }
  .carousel-control-prev {
    left: 31%;
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    width: 10.85px;
    height: 18.95px;
  }
  .carousel-caption {
    left: -10%;
    bottom: -2%;
  }
}
@media screen and (max-width: 400px) {
  .pageName {
    font-size: 22px;
    line-height: 26px;
  }
  .carousel-indicators {
    bottom: 3%;
  }
  .carousel-indicators .active {
    width: 15px !important;
  }
  .carousel-indicators [data-bs-target] {
    width: 5px;
  }
  .carousel-control-prev,
  .carousel-control-next {
    width: 24px;
    height: 24px;
  }
  .carousel-control-next {
    right: 37%;
  }
  .carousel-control-prev {
    left: 37%;
  }
  .carousel-control-next-icon {
    width: 9.04px;
    height: 15.79px;
  }

  .carousel-caption {
    left: -12%;
    bottom: -10%;
  }
}

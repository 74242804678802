.hide {
  display: none !important;
}
/* .active {
  display: block !important;
} */
@media screen and (max-width: 1200px) {
  .custom_component {
    width: 600px;
    height: 310px;
  }
}
@media screen and (max-width: 600px) {
  .custom_component {
    width: 335px;
    height: 160px;
  }
}
